<template>
    <header class="header">
        <div class="container">
            <div class="wrapper">
                <div class="logo">
                    <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"
                         style="width: 40px; height: 40px;">
                        <path d="M8.39106 17.1819H0V25.7242H8.39106V17.1819Z" fill="#FF3495"></path>
                        <path
                            d="M32.6528 40.4577H55.4484V49H32.6528V40.4577ZM42.9279 20.9611C45.514 20.9611 47.6089 23.0938 47.6089 25.7266C47.6089 28.3593 45.514 30.492 42.9279 30.492H32.6528V39.0343H42.9279C48.4868 39.0343 53.2473 35.4838 55.1329 30.492C55.6936 29.0111 56 27.405 56 25.7266C56 18.389 50.1363 12.4188 42.9279 12.4188C35.7195 12.4188 29.8558 18.3882 29.8558 25.7266H38.2468C38.2468 23.0938 40.3417 20.9611 42.9279 20.9611Z"
                            fill="white"></path>
                        <path
                            d="M18.1825 40.4577H31.2547V49H22.8636C15.6445 49 9.79148 43.0414 9.79148 35.6922V7H18.1825V17.1842H27.9725V25.7266H18.1825V40.4577Z"
                            fill="white"></path>
                    </svg>
                </div>
                <h3>Партнер SimDelivery</h3>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'Tele2Header',
}
</script>
<style scoped>
.header {
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    color: #fff;
    background: #1f2229;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    margin-right: 27px;
}

@media all and (min-width: 840px) {
    .logo {
        max-width: 59px
    }
}

@media all and (max-width: 839px) {
    .logo {
        max-width: 48px
    }
}
</style>

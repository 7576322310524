
<template>

    <div class="leading-normal tracking-normal text-white gradient font-sans">

        <Topbar />
        <Title @call-me="callMe" :city="city" />
        <Advantages />
        <Services @call-me="callMe" />
        <Prices />
        <RequestCall @call-me="callMe" />
        <Footer />
        <CallMeDialog
            ref="callMeDialog"
            :country="country"
            :city="city"
        />

    </div>

</template>

<script>

import Topbar from "@/components/Topbar";
import Title from "@/components/Title";
import Advantages from "@/components/Advantages";
import Services from "@/components/Services";
import Prices from "@/components/Prices";
import RequestCall from "@/components/RequestCall";
import Footer from "@/components/Footer";
import CallMeDialog from "@/components/CallMeDialog";
import axios from 'axios'

export default {
    components: {
        CallMeDialog,
        Footer,
        RequestCall,
        Prices,
        Services,
        Advantages,
        Title,
        Topbar
    },
    data() {
        return {
            country: '?',
            city: '?'
        }
    },
    methods: {
        callMe() {
            this.$refs.callMeDialog.show()
        },
    },
    mounted() {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address";
        let token = "4f3193baab7b7e3a2ba6e8a5df7a96508bc52063";
        let options = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            }
        }
        axios(
            url, options
        ).then(r => {
            let location = r.data.location;
            if (location) {
                let d = location.data
                this.country = d.country;
                this.city = d.city;
            }
        }).catch(error => console.log("error", error))
        if (this.$route.query.contact) {
            this.callMe()
        }
    }
}
</script>

<style>

.gradient {
    background: linear-gradient(50deg, #4e73df, #132249);
}
button:active, button:focus {
    outline: none !important;
}
button::-moz-focus-inner {
    border: 0 !important;
}

</style>

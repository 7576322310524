<template>
  <div class="page">
    <Tele2Header />
    <div class="content">
      <div class="container">
        <div class="card">
          <Tele2ApplicationForm
              v-if="hasInternetConnectionBeenChecked"
              :address="formData.address"
              :entrance="formData.entrance"
              :floor="formData.floor"
          />
          <Tele2InternetConnectionCheckForm
              v-else
              @close="hasInternetConnectionBeenChecked = true"
              @change-address="changeAddress"
              @change-entrance="changeEntrance"
              @change-floor="changeFloor"
          />
        </div>
      </div>
    </div>
    <Tele2Footer />
  </div>
</template>

<script>
import Vue from "vue";
import '@/assets/css/tele2.css';
import Tele2Header from "@/components/tele2/Tele2Header.vue";
import Tele2ApplicationForm from "@/components/tele2/Tele2ApplicationForm.vue";
import Tele2Footer from "@/components/tele2/Tele2Footer.vue";
import Tele2InternetConnectionCheckForm from "@/components/tele2/Tele2InternetConnectionCheckForm.vue";

export default {
  components: {
    Tele2InternetConnectionCheckForm,
    Tele2Footer,
    Tele2ApplicationForm,
    Tele2Header
  },
  data() {
    return {
      formData: {
        address: null,
        entrance: null,
        floor: null,
      },
      hasInternetConnectionBeenChecked: false
    };
  },
  methods: {
    changeAddress(address) {
      this.formData.address = address;
    },
    changeEntrance(entrance) {
      this.formData.entrance = entrance;
    },
    changeFloor(floor) {
      this.formData.floor = floor;
    }
  },
  created() {
    Vue.nextTick(() => {
      document.title = 'Домашний интернет t2';
    });
  }
}
</script>
<style scoped>

@font-face {
    font-family: 't2_halvarbreit-extrabold';
    font-style: normal;
    src: url('/fonts/T2_HalvarBreit-ExtraBold.15d4d2bf.otf');
}

@font-face {
    font-family: 't2_rooftop-regular';
    font-style: normal;
    src: url('/fonts/T2_Rooftop-Regular.1e4819ce.otf');
}

.page {
  --base-font-family: t2_rooftop-regular,sans-serif;
  --bold-font-family: t2_halvarbreit-extrabold,serif;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  color: #1f2229;
  background: #fff;
  font: 16px/1.375 var(--base-font-family);
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none
}

.content {
  padding: 54px 0;
  flex: 1 0 auto;
}

.card {
  padding: 40px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);
  border-radius: 12px;
}

@media (max-width: 767px) {
  .card {
    padding: 32px 18px 32px;
    border-radius: 0;
  }
}
</style>

<template>
    <div>
        <div v-if="isTextInside" class="image-container">
            <img :src="prize.image || '/gift-box.png'" :alt="prize.name" class="image">
            <div class="text text-overlay">{{ prize.name }}
            </div>
        </div>
        <div class="container-outside" v-else>
            <img :src="prize.image || '/gift-box.png'" :alt="prize.name" class="image-container">
            <div class="text text-overlay-outside">{{ prize.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        prize: {
            required: true
        },
        isTextInside: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
.image {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.image-container {
    max-width: 190px;
    max-height: 300px;
    object-fit: contain;
}

.text {
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: t2_rooftop-regular, sans-serif;
}

.text-overlay {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
}

.container-outside {
    display: flex;
    gap: 1vw;
    align-items: center;
    align-content: center;
}

.text-overlay-outside {
    font-size: 30px;
    padding-bottom: 10px;
}

@media (max-width: 1440px) {
    .text-overlay-outside {
        font-size: 25px;
    }
}

@media (max-width: 1280px) {
    .text-overlay-outside {
        font-size: 2vw;
    }
}

@media (max-width: 1024px) {
    .image-container {
        width: 15vw;
        height: 20vw;
    }
}

@media (max-width: 768px) {
    .image-container {
        width: 30vw;
        height: 40vw;
    }
    .text-overlay-outside {
        font-size: 4vw;
    }
}

@media (max-width: 480px) {
}
</style>

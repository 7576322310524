<template>
    <div class="pageContainer">
        <ModalHOC :show="showModal" @update:show="showModal = $event"
                  :has-close-icon="false"
                  :is-close-by-click-outside="false">
            <div class="winModal">
                <p class="winTitle">{{ findPrize(selectedPrizeId).name }}</p>
                <p class="winText">{{ findPrize(selectedPrizeId).winText }}</p>
                <button class="winButton" @click="showModal = false">Играть дальше</button>
            </div>
        </ModalHOC>
        <!-- background icons -->
        <img class="icon gift" draggable="false" src="@/assets/gift.svg" alt="подарок">
        <img class="icon sim-card" draggable="false" src="@/assets/sim_card.svg" alt="сим-карта">
        <img class="icon mobile-internet" draggable="false" src="@/assets/mobile_Internet.svg" alt="мобильный интернет">

        <div class="contentContainer">
            <div class="titleContainer">
                <p class="fortuneTitle">Беспроигрышное колесо фортуны</p>
                <div class="operatorContainer">
                    <div class="logoContainer">
                        <img class="logoImg" src="@/assets/logo_simdelivery.svg" alt="SimDelivery">
                        <p class="logoAnd">&</p>
                        <img class="logoImg" src="@/assets/logo_tele2.svg" alt="Tele2">
                    </div>
                    <p class="operatorText">от мобильного оператора TELE2</p>
                </div>
            </div>

            <div :class="['actionContainer', {'mobileAfter': isYourPrizesVisible} ]">
                <div class="prizesContainer">
                    <div class="yourPrizes" v-if="isYourPrizesVisible">
                        <p class="prizeTitle">Ваши призы</p>
                        <PrizeCard :is-text-inside="false" v-for="item in prizes" :key="item.id" :prize="item"/>
                    </div>
                    <div v-else class="defaultPrizes">
                        <img class="imgPrize" src="@/assets/3month.png"
                             alt="3 месяца бесплатного домашнего интернета">
                        <img class="imgPrize" src="@/assets/100rub.png" alt="100 рублей на счет телефона">
                        <img class="imgPrize" src="@/assets/t-shirt.png" alt="Фирменная футболка Теле2">
                    </div>
                </div>

                <div class="wheelContainer">
                    <p class="attemptCount">Всего попыток: {{ attemptCount }}</p>
                    <div :class="['fortune-wheel-border', { animating: isAnimating, disabled: attemptCount === 0 }]">
                        <FortuneWheel
                            :style="{ userSelect: 'none' }"
                            :canvas="canvasOptions"
                            :prizeId="selectedPrizeId"
                            :prizes="allPrizes"
                            :verify="true"
                            :duration="1000"
                            :disabled="attemptCount === 0"
                            @rotateStart="onCanvasRotateStart"
                            @rotateEnd="onRotateEnd"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FortuneWheel from 'vue-fortune-wheel'
import 'vue-fortune-wheel/lib/vue-fortune-wheel.css'
import prizesArray from './prizes.json'
import ModalHOC from "@/components/ModalHOC.vue";
import PrizeCard from "@/components/mobileBonus/prizeCard.vue";

export default {
    components: {
        PrizeCard,
        ModalHOC,
        FortuneWheel,
    },
    data() {
        return {
            showModal: false,
            prizes: [],
            attemptCount: 2,
            isYourPrizesVisible: false,
            isAnimating: false,
            selectedPrizeId: 0,
            canvasOptions: {
                btnText: 'Старт',
                btnWidth: 150,
                fontSize: 24,
                textLength: 12,
                radius: 280,
                textRadius: 240
            },
            allPrizes: prizesArray,
        }
    },
    methods: {
        onCanvasRotateStart(rotate) {
            if (this.attemptCount > 0) {
                this.attemptCount--
                this.isAnimating = true;
                this.requestTest()
                rotate() // Call the callback, start spinning
            }
        },
        onRotateEnd(prize) {
            if (!this.isYourPrizesVisible) {
                this.isYourPrizesVisible = true
            }
            this.isAnimating = false;
            this.showModal = true
            this.prizes.push(prize)
        },
        requestTest() {
            this.selectedPrizeId = 1
        },
        findPrize(id) {
            return this.allPrizes.find(prize => prize.id === id) || {}
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.pageContainer {
    background: linear-gradient(116.96deg, #2D88F1 0%, #FC0540 99.61%);
    display: flex;
    justify-content: center;
    position: relative;
    font-family: t2_rooftop-regular, sans-serif;
    min-height: 100%;
}

.contentContainer {
    width: 1440px;
    padding: 1vw;
}

.titleContainer {
    display: flex;
    justify-content: space-between;

    .fortuneTitle {
        max-width: 40%;
        font-size: 60px;
        text-transform: uppercase;
        color: white;
        line-height: 1.2;
    }

    .logoContainer {
        display: flex;
        padding: 10px 30px;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: 100px;
        width: fit-content;
        justify-content: center;
        align-items: center;

        .logoAnd {
            font-size: 40px;
            color: white;
            margin-inline: 20px;
        }

        .logoImg {
            height: 50px;
        }
    }

    .operatorContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%
    }

    .operatorText {
        margin-top: 0.5vw;
        font-size: 35px;
        line-height: 1.1;
        text-align: center;
        max-width: 50%;
        color: rgba(30, 30, 30, 1);
    }
}

.actionContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2vw;
}

.prizesContainer {
    height: fit-content;
    width: 650px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 2vw;

    .yourPrizes {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        gap: 20px;

        .prizeTitle {
            flex: 1;
            text-align: center;
            font-size: 2.5vw;
            color: white;
            line-height: 1;
        }
    }

    .defaultPrizes {
        display: flex;
        gap: 20px;

        .imgPrize {
            max-width: 189px;
            max-height: 299px;
            object-fit: contain
        }
    }
}

.wheelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 50%;

    .attemptCount {
        font-size: 2vw;
        color: white;
        text-align: center;
    }
}

@keyframes prizeCardAnimation {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes border-dance {
    0% {
        border-color: rgba(240, 237, 67, 1);
    }
    50% {
        border-color: white;
    }
    100% {
        border-color: rgba(240, 237, 67, 1);
    }
}

.fortune-wheel-border {
    z-index: 2;
    border: 1vw dotted white;
    border-radius: 50%;
    padding: 6px 6px 0 6px;
    background-color: rgba(59, 59, 59, 1);
}

.fortune-wheel-border.disabled {
    border-color: darkgray;
}

.fortune-wheel-border.animating {
    animation: border-dance 1.5s infinite;
}

.winModal {
    background-color: white;
    padding: 40px;
    border-radius: 30px;
    font-family: sans-serif;
    text-align: center;
    max-width: 750px;

    .winTitle {
        font-size: 40px;
        font-weight: bold;
        color: #333;
    }

    .winText {
        font-size: 20px;
        color: #555;
        margin-block: 2vw;
    }

    .winButton {
        background-color: #15bd96;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 40px;
        border-radius: 15px;
        cursor: pointer;
    }
}


@media (max-width: 1440px) {
    .titleContainer {
        .fortuneTitle {
            font-size: 4vw;
        }
    }

    .prizesContainer {
        max-width: 45vw;

        .defaultPrizes {
            justify-content: center;

            .imgPrize {
                max-width: 15vw;
                max-height: 20vw;
            }

        }
    }
}

@media (max-width: 1280px) {
    .titleContainer {
        .fortuneTitle {
            font-size: 4.5vw;
            max-width: 50%;
        }

        .operatorText {
            font-size: 3vw;
        }
    }

    .prizesContainer {
        padding: 1vw;

        .yourPrizes {
            gap: 1vw;

            .prizeTitle {
                font-size: 3.5vw;
            }
        }

        .defaultPrizes {
            gap: 1vw;
        }
    }

    .wheelContainer {
        gap: 1vw;

        .attemptCount {
            font-size: 2vw;
        }
    }
}

@media (max-width: 1024px) {
    .titleContainer {
        .logoContainer {
            padding: 1.2vw 2.3vw;

            .logoAnd {
                font-size: 3.5vw;
                margin-inline: 1vw;
            }

            .logoImg {
                height: 5.5vw;
            }
        }
    }
}

@media (max-width: 768px) {
    .prizesContainer {
        padding: 1vw;
        width: auto;
        max-width: 100vw;
        justify-content: center;

        .yourPrizes {
            justify-content: center;

            .prizeTitle {
                font-size: 5vw;
            }
        }

        .defaultPrizes {
            justify-content: center;

            .imgPrize {
                max-width: 20vw;
                max-height: 30vw;

            }
        }
    }

    .wheelContainer {
        justify-content: center;
        align-content: center;
        width: 80%;

        .attemptCount {
            font-size: 4.5vw;
            line-height: 1;
        }
    }

    .titleContainer {
        flex-direction: column;

        .fortuneTitle {
            max-width: 100%;
            text-align: center;
            font-size: 5vw;
        }

        .logoContainer {
            padding: 1vw 6vw;

            .logoAnd {
                font-size: 5vw;
                margin-inline: 2vw;
            }

            .logoImg {
                height: 8vw;
            }
        }

        .operatorContainer {
            width: 100vw;
            flex-direction: row;
        }

        .operatorText {
            font-size: 4vw;
            max-width: 40%;
        }
    }

    .icon {
        width: 20vw;
    }

    .actionContainer {
        flex-direction: column;
        align-items: center;
        gap: 1vw;
    }

    /* не удалять */
    .mobileAfter {
        flex-direction: column-reverse;
    }
}


@media (max-width: 480px) {
    .prizesContainer {
        padding: 1vw;
        width: auto;
        max-width: 100vw;
        justify-content: center;

        .yourPrizes {
            justify-content: center;

            .prizeTitle {
                font-size: 6vw;
            }
        }

        .defaultPrizes {
            justify-content: center;

            .imgPrize {
                max-width: 30vw;
                max-height: 40vw;

            }
        }
    }

    .wheelContainer {
        justify-content: center;
        align-content: center;
        width: 90%;

        .attemptCount {
            font-size: 6vw;
        }
    }

    .titleContainer {
        flex-direction: column;

        .fortuneTitle {
            max-width: 100%;
            text-align: center;
            font-size: 8vw;
        }

        .logoContainer {
            padding: 1.2vw 2.3vw;
            width: 100%;

            .logoAnd {
                font-size: 8vw;
                margin-inline: 2vw;
            }

            .logoImg {
                height: 10vw;
            }
        }

        .operatorContainer {
            flex-direction: column;
        }

        .operatorText {
            font-size: 5vw;
            max-width: 100%;
        }

    }

    .icon {
        width: 20vw;
    }

    .actionContainer {
        flex-direction: column;
        align-items: center;
    }
}


.icon {
    user-select: none;
    position: absolute;
}

.gift {
    top: 60vh;
    left: 30vw;
}

.sim-card {
    top: 10vh;
    left: 50vw;
}

.mobile-internet {
    bottom: 3vh;
    right: 0;
}
</style>

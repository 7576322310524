import Vue from 'vue'
import App from './App.vue'
import MainPage from '@/pages/MainPage.vue'
import LinksPage from "@/pages/LinksPage";
import Tele2InternetPage from "@/pages/Tele2InternetPage.vue";
import '@/assets/css/tailwind.css'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {faMapMarkerAlt, faPhoneAlt, faEnvelope, faClock} from '@fortawesome/free-solid-svg-icons'
import {faTelegram, faVk, faInstagram} from '@fortawesome/free-brands-svg-icons'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueRouter from 'vue-router'
import PrivacyPage from "@/pages/PrivacyPage.vue";
import MobileBonusPage from "@/pages/MobileBonusPage.vue";

library.add(faMapMarkerAlt)
library.add(faPhoneAlt)
library.add(faEnvelope)
library.add(faClock)
library.add(faTelegram, faVk, faInstagram)

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 2,
    newestOnTop: true
})

Vue.use(VueRouter)

const routes = [
    {path: '/', component: MainPage},
    {path: '/t2-internet', component: Tele2InternetPage, props: true},
    {path: '/mobile-bonus', component: MobileBonusPage, props: true},
    {path: '/privacy', component: PrivacyPage},
    {path: '/l/:name', component: LinksPage, props: true},
    {path: '*', component: MainPage},
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

const app = new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
